const STAGING_HOST = ['utility.staging.xcarepro.com', 'utility.stagingus.xcarepro.com'];
const DEV_HOST = ['utility.dev.xcarepro.com', 'localhost'];
const PROD_HOST = ['utility.xcarepro.com', 'utility.us.xcarepro.com'];
const hostname = window.location.hostname;
let configs: any = {};
if (DEV_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Development environment
    // const SOURCE = "https://staging.xcarepro.com/";
    const SOURCE = "https://dev.xcarepro.com/";
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'api_v2/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        GOOGLE_API_KEY: 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8',
        COOKIE_DOMAIN: '.dev.xcarepro.com',
        PATIENT_COOKIE_DOMAIN: '.utility.dev.xcarepro.com',
        PATIENT_URL: 'http://patient.dev.xcarepro.com/',
        CLINIC_URL: 'http://app.dev.xcarepro.com/',
        FRONT_URL: 'https://dev.xcarepro.com',
        PATIENT_APPOINTMENT_URL: 'http://patient.dev.xcarepro.com/appointments',
        CHAT_COLLECTION_NAME: 'chat_dev' //chat for staging and chat_live for live
    }
} else if (STAGING_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Development environment
    let SOURCE = "https://staging.xcarepro.com/";
    if (hostname === 'utility.stagingus.xcarepro.com') {
        SOURCE = "https://stagingus.xcarepro.com/";
    }
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'api_v2/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        GOOGLE_API_KEY: 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8',
        COOKIE_DOMAIN: hostname === 'utility.stagingus.xcarepro.com' ? '.stagingus.xcarepro.com' : '.staging.xcarepro.com',
        PATIENT_COOKIE_DOMAIN: hostname === 'utility.stagingus.xcarepro.com' ? '.utility.stagingus.xcarepro.com' : '.utility.staging.xcarepro.com',
        PATIENT_URL: hostname === 'utility.stagingus.xcarepro.com' ? 'http://patient.stagingus.xcarepro.com/' : 'http://patient.staging.xcarepro.com/',
        CLINIC_URL: hostname === 'utility.stagingus.xcarepro.com' ? 'http://app.stagingus.xcarepro.com/' : 'http://app.staging.xcarepro.com/',
        FRONT_URL: hostname === 'utility.stagingus.xcarepro.com' ? 'https://stagingus.xcarepro.com' : 'https://staging.xcarepro.com',
        PATIENT_APPOINTMENT_URL: hostname === 'utility.stagingus.xcarepro.com' ? 'http://patient.stagingus.xcarepro.com/appointments' : 'http://patient.staging.xcarepro.com/appointments',
        CHAT_COLLECTION_NAME: hostname === 'utility.stagingus.xcarepro.com' ? 'us_chat' : 'chat' //chat for staging and chat_live for live
    }
} else if (PROD_HOST.findIndex(item => { return item === hostname }) !== -1) {
    // Production environment
    let SOURCE = "https://www.xcarepro.com/";
    if (hostname === 'utility.us.xcarepro.com') {
        SOURCE = "https://us.xcarepro.com/";
    }
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'api_v2/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        GOOGLE_API_KEY: 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8',
        COOKIE_DOMAIN: hostname === 'utility.us.xcarepro.com' ? '.us.xcarepro.com' : '.xcarepro.com',
        PATIENT_COOKIE_DOMAIN:hostname === 'utility.us.xcarepro.com' ? '.utility.us.xcarepro.com' : '.utility.xcarepro.com',
        PATIENT_URL: hostname === 'utility.us.xcarepro.com' ? 'http://patient.us.xcarepro.com/' : 'http://patient.xcarepro.com/',
        CLINIC_URL: hostname === 'utility.us.xcarepro.com' ? 'http://app.us.xcarepro.com/' : 'http://app.xcarepro.com/',
        FRONT_URL: hostname === 'utility.us.xcarepro.com' ? 'https://us.xcarepro.com' : 'https://xcarepro.com',
        PATIENT_APPOINTMENT_URL: hostname === 'utility.us.xcarepro.com' ? 'http://patient.us.xcarepro.com/appointments' : 'http://patient.xcarepro.com/appointments',
        CHAT_COLLECTION_NAME: hostname === 'utility.us.xcarepro.com' ? 'us_chat_live' : 'chat_live' //chat for staging and chat_live for live
    }
}
export default configs;