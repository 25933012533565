import { KeyboardDatePicker } from '@material-ui/pickers';
import Cookies from 'js-cookie';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AppointmentBook } from '../../components/LandingPageSearchNew/pages/AppointmentBook/AppointmentBook';
import { showAddress } from '../../components/renderAddress';
import configs from '../../configs/apiConfigs';
import { appointmentSlot, clinicAndProviderList, patientblocksearch } from '../../services/landingPageSearch';
import { IAppState } from '../../store';
import { addClinicProviderList } from '../../store/clinicAndProviderList/actions';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../store/loadingIndicator/actions';

export const ProviderList = () => {
    const searchFilterData = useSelector((state: IAppState) => state.searchFilter.searchFilterData, shallowEqual);
    const _clinicProviderList: any = useSelector((state: IAppState | any) => state.clinicProviderList.clinicProviderList, shallowEqual);
    const dispatch = useDispatch();
    const [providers, setProviders] = useState([]);
    const [clinic, setClinic] = useState(null);
    const [selectedProvider, setSelectedProvider]: any = useState(null);
    const [selectedDate, setSelectedDate] = useState(moment().toDate());
    const [isAppointmentBook, setIsAppointmentBook] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [selectedSlot, setSelectedSlot] = useState('');
    const [blockSearchList, setBlockSearchList] = useState([]);
    const [patientBlockSearchRequest, setPatientBlockSearchRequest]: any = useState('');
    const [slots, setSlot]: any = useState([]);
    const [asapAppt, setAsapAppt] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        callGetProviders();
    }, []);
    const callGetProviders = () => {
        const { newSpeciality, location, date, howToPay, patientUniqueId, page, languages, distance, gender, clinicId, providerId } = searchFilterData;
        let latitude: any = "", longitude: any = "", langIds: any = [], clinic: any = '';
        if (location !== "") {
            latitude = location.latitude;
            longitude = location.longitude;
        }
        if (languages && languages.length > 0) {
            langIds = languages.map((item: any) => {
                return item.value
            });
        }
        let request = {
            location: {
                latitude: latitude,
                longitude: longitude,
                specialityId: newSpeciality,
                searchdate: date !== null ? date : "",
                radiuskm: distance,
                gender: gender ? gender.toString() : '',
                languages: langIds.toString(),
                insuranceProviderId: howToPay.type === 'insurance' ? howToPay.value : '',
                acceptAssignment: howToPay.type === 'cash' ? 'no' : '',
                socialAssistTypeID: howToPay.type === 'socialAssistance' ? howToPay.value : '',
                clinicId: clinicId,
                providerId: providerId,
                patientUniqueId: '',
            }
        }
        dispatch(fetchLoadingPending());
        try {
            clinicAndProviderList(request).then((success: any) => {
                if (success && success.response && success.response.data && success.response.status === 200) {
                    if (success.response.data && success.response.data.status.error === false) {
                        setTimeout(() => {
                            dispatch(fetchLoadingSuccess(false));
                        }, 2000);
                        setProviders(success.response.data.doctors);
                        setClinic(success.response.data.clinic);
                        dispatch(addClinicProviderList({
                            ..._clinicProviderList,
                            clinicList: _.uniqBy(success.response.data.clinic, 'id'),
                            providerList: success.response.data.doctors,
                            providerTotalCount: success.response.data.total
                        }));
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                    } else if (success.response.data && success.response.data.status.error === true) {
                        setProviders([]);
                        console.log('error');
                        dispatch(fetchLoadingSuccess(false));
                    }
                }
            });
        } catch (error) {
            toast.error('Something went wrong.');
            console.log('error', error);
            dispatch(fetchLoadingSuccess(false));
        }
    }
    const fetchAppointmentSlot = () => {
        var request: any = [];
        if (selectedProvider && selectedDate) {
            request.push({
                "doctorId": selectedProvider.id,
                "clinicId": selectedProvider.clinic_id,
                "start_date": moment(selectedDate).format('YYYY-MM-DD'),
                "end_date": moment(selectedDate).format('YYYY-MM-DD')
            });
            let data = { 'appointmentData': request };
            appointmentSlot(data).then((success: any) => {
                try {
                    if (success && success.response && success.response.data && success.response.status === 200) {
                        if (success.response.data && success.response.data.status.error === false) {
                            console.log(success.response.data);
                            setSlot(success.response.data.appointments);
                        } else {
                            if (success.response.data && success.response.data.status.error === true) {
                                toast.error(success.response.data.status.msg);
                            }
                        }
                    }
                } catch (error) {
                    console.log('error', error);
                }
            });
        }
    }
    const handlePatientBlockSearch = () => {
        var request: any = [];
        if (providers.length > 0) {
            providers.map((item: any) => {
                request.push({
                    "doctorId": item.id,
                    "clinicId": item.clinic_id,
                });
            });
            let data = { 'blockData': request };
            if (JSON.stringify(patientBlockSearchRequest) !== JSON.stringify(data)) {
                setPatientBlockSearchRequest(data);
                patientblocksearch(data).then((success: any) => {
                    try {
                        if (success && success.response && success.response.data && success.response.status === 200) {
                            if (success.response.data && success.response.data.status.error === false) {
                                setBlockSearchList(success.response.data.blockdata)
                            } else {
                                if (success.response.data && success.response.data.status.error === true) {
                                    // toast.error(success.response.data.status.msg);
                                }
                                setBlockSearchList([]);
                            }
                        }
                    } catch (error) {
                        console.log('error', error);
                    }
                });
            }
        }
    }
    const clickAddToWaitList = (item: any) => {
        setSelectedDoctor(item);
        setSelectedSlot('');
        setIsAppointmentBook(true);
    }
    const appointmentBookModalClose = () => {
        setIsAppointmentBook(false);
        // Cookies.remove('patient_cookie');
        // Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
        setAsapAppt("");
    }
    const bookAppointment = (slot: any, doctor: any) => {
        setSelectedDoctor(doctor);
        setSelectedSlot(slot);
        setIsAppointmentBook(true);
    }
    useEffect(() => {
        fetchAppointmentSlot();
        handlePatientBlockSearch();
    }, [selectedDate, selectedProvider]);
    const handleChange = (date: any) => {
        if (moment(date).toDate() !== selectedDate) {
            setSelectedDate(moment(date).toDate());
            setSelectedSlot('');
        }
    };
    if (selectedProvider) {
        if (selectedProvider?.sharecalendar.utilityCalender) {
            return (
                <section className="p-details">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-12 px-0">
                                <div className="p-4 box-sec">
                                    <div className="d-flex justify-content-between align-items-center top-strip">
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedProvider(null);
                                        }} className="cursor-pointer text-primary"><i className="bi bi-chevron-left"></i> Back</a>
                                        <div className="fw-bold text-center fs-5">{selectedProvider?.fullname?.length < 20 ? selectedProvider?.fullname : selectedProvider?.fullname?.substring(0, 20) + '...'}<span
                                            className="text-secondary fs-6 fw-normal d-block">{selectedProvider && selectedProvider.speciality_name}</span></div>
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                        }} className="cursor-pointer"></a>
                                    </div>
                                    <div className="mid-box pt-3 pb-5">
                                        <div className="mb-5">
                                            <div className="d-flex justify-content-between align-items-center top-strip">
                                                <a onClick={() => {
                                                    setSelectedDate(moment(selectedDate).subtract(1, 'day').toDate())
                                                }}><i className="bi bi-chevron-left"></i></a>
                                                <div className="text-center fs-5 text-primary cursor-pointer" onClick={() => setIsOpen(true)}>{moment(selectedDate).format("MMM DD, YYYY")}</div>
                                                <a onClick={() => {
                                                    setSelectedDate(moment(selectedDate).add(1, 'day').toDate())
                                                }}><i className="bi bi-chevron-right"></i></a>
                                            </div>
                                            <div className="input-selectors input-check mt-3 d-flex justify-content-between">
                                                <div className="form-check p-0 position-relative text-center">
                                                    <div className="small text-secondary">
                                                        {
                                                            selectedDate &&
                                                            <>{moment(selectedDate).subtract(2, 'day').format('ddd')}</>
                                                        }
                                                    </div>
                                                    <input
                                                        className="m-auto rounded-circle position-absolute top-0 start-0 w-100 h-100 form-check-input"
                                                        type="radio" name="dateList" onClick={() => {
                                                            setSelectedDate(moment(selectedDate).subtract(2, 'day').toDate())
                                                        }} />
                                                    <label className="w-100 fs-5 rounded-circle form-check-label">
                                                        {
                                                            selectedDate &&
                                                            <>{moment(selectedDate).subtract(2, 'day').format('DD')}</>
                                                        }
                                                    </label>
                                                </div>
                                                <div className="form-check p-0 position-relative text-center">
                                                    <div className="small text-secondary">
                                                        {
                                                            selectedDate &&
                                                            <>{moment(selectedDate).subtract(1, 'day').format('ddd')}</>
                                                        }
                                                    </div>
                                                    <input
                                                        className="m-auto rounded-circle position-absolute top-0 start-0 w-100 h-100 form-check-input"
                                                        type="radio" name="dateList" onClick={() => {
                                                            setSelectedDate(moment(selectedDate).subtract(1, 'day').toDate())
                                                        }} />
                                                    <label className="w-100 fs-5 rounded-circle form-check-label">
                                                        {
                                                            selectedDate &&
                                                            <>{moment(selectedDate).subtract(1, 'day').format('DD')}</>
                                                        }
                                                    </label>
                                                </div>
                                                <div className="form-check p-0 position-relative text-center">
                                                    <div className="small text-secondary">
                                                        {
                                                            selectedDate &&
                                                            <>{moment(selectedDate).format('ddd')}</>
                                                        }
                                                    </div>
                                                    <input
                                                        className="m-auto rounded-circle position-absolute top-0 start-0 w-100 h-100 form-check-input"
                                                        type="radio" name="dateList" checked onClick={() => {
                                                            setSelectedDate(moment(selectedDate).toDate())
                                                        }} />
                                                    <label className="w-100 fs-5 rounded-circle form-check-label">
                                                        {
                                                            selectedDate &&
                                                            <>{moment(selectedDate).format('DD')}</>
                                                        }
                                                    </label>
                                                </div>
                                                <div className="form-check p-0 position-relative text-center">
                                                    <div className="small text-secondary">
                                                        {
                                                            selectedDate &&
                                                            <>{moment(selectedDate).add(1, 'day').format('ddd')}</>
                                                        }
                                                    </div>
                                                    <input
                                                        className="m-auto rounded-circle position-absolute top-0 start-0 w-100 h-100 form-check-input"
                                                        type="radio" name="dateList" onClick={() => {
                                                            setSelectedDate(moment(selectedDate).add(1, 'day').toDate())
                                                        }} />
                                                    <label className="w-100 fs-5 rounded-circle form-check-label">
                                                        {
                                                            selectedDate &&
                                                            <>{moment(selectedDate).add(1, 'day').format('DD')}</>
                                                        }
                                                    </label>
                                                </div>
                                                <div className="form-check p-0 position-relative text-center">
                                                    <div className="small text-secondary">
                                                        {
                                                            selectedDate &&
                                                            <>{moment(selectedDate).add(2, 'day').format('ddd')}</>
                                                        }
                                                    </div>
                                                    <input
                                                        className="m-auto rounded-circle position-absolute top-0 start-0 w-100 h-100 form-check-input"
                                                        type="radio" name="dateList" onClick={() => {
                                                            setSelectedDate(moment(selectedDate).add(2, 'day').toDate())
                                                        }} />
                                                    <label className="w-100 fs-5 rounded-circle form-check-label">
                                                        {
                                                            selectedDate &&
                                                            <>{moment(selectedDate).add(2, 'day').format('DD')}</>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-5">
                                            {
                                                slots && slots[0] && slots[0].isClosedClinic &&
                                                <div className="form-check p-0 position-relative text-center">
                                                    <label>
                                                        Appointments accepted by request only
                                                    </label>
                                                </div>
                                            }
                                            <div className="input-selectors input-radio mt-3 d-flex flex-wrap">
                                                {
                                                    !(slots && slots[0] && slots[0].isClosedClinic) &&
                                                    slots.map((slot: any) => {
                                                        return (
                                                            <div className="form-check p-0 position-relative">
                                                                <input
                                                                    disabled={slot.avability !== "yes" || slot.isClosedClinic}
                                                                    className="form-check-input m-auto position-absolute top-0 start-0 w-100 h-100"
                                                                    type="radio" name="timeList" id="t1" value=""
                                                                    onClick={() => {
                                                                        setSelectedSlot(slot);
                                                                        // bookAppointment(slot, selectedProvider);
                                                                    }}
                                                                />
                                                                <label className="rounded-3 w-100 text-center form-check-label">{slot.startTime}</label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-strip">
                                        {
                                            !(slots && slots[0] && slots[0].isClosedClinic) &&
                                            <><button type="submit" className="btn btn-primary btn-lg my-1 w-100 fs-6" onClick={() => {
                                                if (selectedSlot) {
                                                    setAsapAppt("Hide");
                                                    bookAppointment(selectedSlot, selectedProvider);
                                                }
                                            }}>Book
                                                Appointment</button>
                                                <div className="text-center text-secondary">Clinic will confirm your appointment</div>
                                            </>
                                        }

                                        <button type="submit" className="btn btn-dark btn-lg mt-3 mb-1 w-100 fs-6" onClick={() => {
                                            clickAddToWaitList(selectedProvider)
                                        }}>Add to waitlist (Emergency)</button>
                                        <p className="text-secondary text-center px-5">Clinic will contact you shortly to schedule this appointment</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (isAppointmentBook) &&
                        <AppointmentBook
                            selectedDoctor={selectedDoctor}
                            selectedSlot={selectedSlot}
                            blockSlotData={blockSearchList}
                            hideModal={appointmentBookModalClose}
                            bookAppointmentSuccess={() => {
                                handlePatientBlockSearch();
                                fetchAppointmentSlot();
                                // Cookies.remove('patient_cookie');
                                // Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                                setSelectedProvider(null);
                                setAsapAppt("");
                            }}
                            asapAppt={asapAppt}
                            selectedDate={selectedDate}
                        />
                    }
                    <KeyboardDatePicker
                        className="select-date"
                        placeholder={'Date'}
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        InputProps={{ readOnly: true }}
                        id="date-picker-dialog"
                        format="YYYY-MM-DD"
                        minDate={new Date()}
                        value={selectedDate}
                        onChange={(date: any) => { handleChange(date) }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </section>
            )
        } else {
            return (
                <section className="p-request-sec">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-12 px-0">
                                <div className="p-4 box-sec">
                                    <div className="d-flex justify-content-between align-items-center top-strip">
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedProvider(null);
                                        }} className="cursor-pointer text-primary"><i className="bi bi-chevron-left"></i> Back</a>
                                        <div className="fw-bold text-center fs-5">{selectedProvider?.fullname?.length < 20 ? selectedProvider?.fullname : selectedProvider?.fullname?.substring(0, 20) + '...'}<span
                                            className="text-secondary fs-6 fw-normal d-block">{selectedProvider && selectedProvider.speciality_name}</span></div>
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                        }}></a>
                                    </div>
                                    <div className="mid-box d-flex align-items-center w-100">
                                        <div className="fs-4 text-center">Appointments accepted by request only</div>
                                    </div>
                                    <div className="bottom-strip">
                                        {

                                        }
                                        <button type="submit" className="btn btn-primary btn-lg my-1 w-100 fs-6" onClick={() => {
                                            setAsapAppt("Show");
                                            clickAddToWaitList(selectedProvider)
                                        }}>Request Appointment</button>
                                        <div className="text-center text-secondary fs-5">OR</div>
                                        <button type="submit" className="btn btn-dark btn-lg mt-2 mb-1 w-100 fs-6" onClick={() => {
                                            clickAddToWaitList(selectedProvider)
                                        }}>Add to waitlist (Emergency)</button>
                                        <p className="text-secondary text-center px-5">Clinic will contact you shortly to schedule this appointment</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (isAppointmentBook) &&
                        <AppointmentBook
                            selectedDoctor={selectedDoctor}
                            selectedSlot={selectedSlot}
                            blockSlotData={blockSearchList}
                            hideModal={appointmentBookModalClose}
                            bookAppointmentSuccess={() => {
                                handlePatientBlockSearch();
                                fetchAppointmentSlot();
                                // Cookies.remove('patient_cookie');
                                // Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                                setSelectedProvider(null);
                                setAsapAppt("");
                            }}
                            asapAppt={asapAppt}
                            selectedDate={selectedDate}
                        />
                    }
                </section>
            )
        }
    }
    return (
        <section className="main-sec">
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-12 px-0">
                        <div className="box-sec">
                            <div className="clinic-info bg-primary pb-4 pt-2 px-4">
                                {/* <a href="" className="d-block text-end mb-2 text-white"><i className="bi bi-x-lg"></i></a> */}
                                <div className="info-box bg-white shadow-sm border py-2 px-2">
                                    <div className="logo-box mb-1 mt-1">
                                        {
                                            _clinicProviderList && _clinicProviderList.cliniclist && _clinicProviderList.cliniclist[0] &&
                                            <img src={configs.IMAGE_URL + 'clinic/' + _clinicProviderList.cliniclist[0].logo.name + '-original.' + _clinicProviderList.cliniclist[0].logo.type} />
                                        }
                                    </div>
                                    <div className="text-center mb-2 mx-2 text-secondary fs-6">
                                        {
                                            _clinicProviderList && _clinicProviderList.cliniclist && _clinicProviderList.cliniclist[0] &&
                                            showAddress(_clinicProviderList.cliniclist[0])
                                        }
                                    </div>
                                </div>
                            </div>
                            <ul className="dr-list px-2 mb-4">
                                {
                                    _clinicProviderList.providerList.map((item: any, index: number) => {
                                        const handleBook = () => {
                                            setSelectedProvider(item);
                                        }
                                        return (
                                            <li className="shadow-sm border d-flex p-3 justify-content-between mt-4 align-items-center" key={item.uniqueid}>
                                                <div className="d-flex align-items-center">
                                                    <div className="img-box rounded-circle me-3">
                                                        {(item && item.photo && item.photo.name) ?
                                                            <img src={configs.IMAGE_URL + 'doctor/' + item.photo.name + '-original.' + item.photo.type} />
                                                            :
                                                            <div key={index + Math.random()} className="img-box"></div>
                                                        }
                                                    </div>
                                                    <div className="fw-bold text-start">{item.fullname}
                                                        <span className=" small text-secondary fs-6 fw-normal d-block">{item.speciality_name && item.speciality_name}</span>
                                                    </div>
                                                </div>
                                                <button className="btn btn-outline-primary" onClick={handleBook}>Book</button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {
                (isAppointmentBook) &&
                <AppointmentBook
                    selectedDoctor={selectedDoctor}
                    selectedSlot={selectedSlot}
                    blockSlotData={blockSearchList}
                    hideModal={appointmentBookModalClose}
                    bookAppointmentSuccess={() => {
                        handlePatientBlockSearch();
                        fetchAppointmentSlot();
                        // Cookies.remove('patient_cookie');
                        // Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                        setSelectedProvider(null);
                        setAsapAppt("");
                    }}
                    asapAppt={asapAppt}
                    selectedDate={selectedDate}
                />
            }
        </section>
    )
}