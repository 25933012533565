import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { allowOnlyAlpha, emailValidation, passwordValidator, takeOnlyDigitAndPlus, allowOnlyDigit } from '../../../../utils/validators';
import { getMasterInsuranceList, registerUser } from '../../services/auth';
import { GuarantorInfoModal } from '../../pages/guarantorInfo/GuarantorInfoModal';
import { OTPModal } from '../../pages/Auth/Otp';
import { signInWithCustomToken } from '../../../../utils/firebaseUtils';
import configs from '../../../../configs/apiConfigs';
import { addPrimaryInsurance, addSecondaryInsurance } from '../../../../store/landingPageSearch/actions';
export const Register = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [masterInsuranceList, setMasterInsuranceList]: any = useState([]);
    const [insurancePlanList, setInsurancePlanList] = useState([]);
    const [showGurantorInfoModal, setShowGuarantorInfoModal] = useState(false);
    const [guarantorList, setGuarantorList]: any = useState([]);
    const [isShowOTPModal, setIsShowOTPModal] = useState(false);
    const [patientId, setPatientId] = useState();
    const [phone, setPhone]: any = useState('');
    const [email, setEmail]: any = useState('');
    const dispatch = useDispatch();
    const [isRegister, setIsRegister] = useState(props.isRegister);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            patienttype: 'individual',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            dob: moment().subtract(1, 'day'),
            gender: 'Male',
            password: '',
            cpassword: '',
            automatedreminder: 'yes',
            pcontactmethod: 'email,text',
            paythroughinsurance: 'no',
            insurancename: '',
            insuranceplanname: '',
            selfguarantor: 'yes',
            primaryguarantor: 'primary',
            secondaryguarantor: '',
            globalError: '',
            otherInsurance: '',
            otherInsuranceplanName: ''
        }
    });
    useEffect(() => {
        setIsLoading(true);
        getMasterInsuranceList().then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                setMasterInsuranceList([...success.insurance, { id: 'other', name: 'Other', plan: [{ id: 'other', name: 'Other' }] }]);
                setValue('insurancename', success.insurance[0].id);
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }, []);
    useEffect(() => {
        if (getValues('insurancename') && getValues('insurancename') !== '') {
            let selectedInsurance: any = getValues('insurancename');
            let insurance = masterInsuranceList.find((item: any) => item.id === selectedInsurance);
            if (insurance) {
                if (selectedInsurance === 'other') {
                    insurance = {
                        ...insurance,
                        plan: [{ id: 'other', name: 'Other' }]
                    }
                } else {
                    insurance = {
                        ...insurance,
                        plan: [...insurance.plan, { id: 'other', name: 'Other' }]
                    }
                }
                setInsurancePlanList(insurance.plan)
                if (insurance.plan[0]) {
                    setValue('insuranceplanname', insurance.plan[0].id);
                }
            }
        }
    }, [watch('insurancename')]);
    const onRegisterClick = (data: any) => {
        console.log('call');
        // console.log(getValues('primaryguarantor'), getValues('secondaryguarantor'), data);
        if (getValues('email') === "" && getValues('phone') === "") {
            setError('globalError', { type: 'custom', message: 'Either email or phone is required.' });
            return;
        } else if (getValues('paythroughinsurance') === 'yes' && getValues('patienttype') === 'individual' && getValues('secondaryguarantor')[0] !== undefined) {
            if (guarantorList.length === 0) {
                setError('globalError', { type: 'custom', message: 'Please add secondary guarantor.' });
                return;
            }
        } else if (getValues('paythroughinsurance') === 'yes' && getValues('patienttype') === 'dependent' && getValues('secondaryguarantor')[0] !== undefined) {
            if (guarantorList.length === 0) {
                setError('globalError', { type: 'custom', message: 'Please add primary and secondary guarantor.' });
                return;
            } else if (guarantorList.length === 1) {
                setError('globalError', { type: 'custom', message: 'Please add secondary guarantor.' });
                return;
            }
        } else if (getValues('paythroughinsurance') === 'yes' && getValues('patienttype') === 'dependent' && getValues('primaryguarantor') !== undefined) {
            if (guarantorList.length === 0) {
                setError('globalError', { type: 'custom', message: 'Please add primary guarantor.' });
                return;
            }
        } else if (getValues('password') !== getValues('cpassword')) {
            setError('globalError', { type: 'custom', message: 'Password and confirm password should be same.' });
            return;
        }
        setError('globalError', {});
        let requestedData = {
            fname: getValues('firstName'),
            lname: getValues('lastName'),
            email: getValues('email'),
            phone: getValues('phone'),
            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
            registeras: "normal",
            password: getValues('password'),
            gender: getValues('gender'),
            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
            reminder: getValues('automatedreminder'),
        }
        try {
            if (getValues('paythroughinsurance') === 'no') {
                if (getValues('patienttype') === 'individual') {
                    Object.assign(requestedData, {
                        isIndividual: true,
                        request: {
                            paymode: 'cash,credit,card,cheque'
                        },
                        guarantor: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            registeras: "normal",
                            password: getValues('password'),
                            gender: getValues('gender'),
                        }
                    });
                } else if (getValues('patienttype') === 'dependent') {
                    Object.assign(requestedData, {
                        isIndividual: false,
                        request: {
                            paymode: 'cash,credit,card,cheque'
                        },
                        guarantor: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            registeras: "normal",
                            password: getValues('password'),
                            gender: getValues('gender'),
                        }
                    });
                }
            } else {
                let insurance = masterInsuranceList.find((item: any) => item.id === getValues('insurancename'));
                let insurancePlan: any = insurancePlanList.find((item: any) => item.id === getValues('insuranceplanname'));
                if (getValues('patienttype') === 'individual') {
                    if (getValues('secondaryguarantor')) {
                        let secondaryguarantor: any = guarantorList[0];
                        Object.assign(requestedData, {
                            isIndividual: true,
                            insurance: {
                                id: insurance.id,
                                name: insurance && insurance.id === 'other' ? getValues('otherInsurance') : insurance.name,
                                plan: insurancePlan && insurancePlan.id ? insurancePlan.id : '',
                                planName: insurancePlan && insurancePlan.id === 'other' ? getValues('otherInsuranceplanName') : insurancePlan.name
                            },
                            guarantor: {
                                fname: getValues('firstName'),
                                lname: getValues('lastName'),
                                email: getValues('email'),
                                phone: getValues('phone'),
                                dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                registeras: "normal",
                                password: getValues('password'),
                                gender: getValues('gender'),
                                insurance: {
                                    id: insurance.id,
                                    name: insurance && insurance.id === 'other' ? getValues('otherInsurance') : insurance.name,
                                    plan: insurancePlan && insurancePlan.id ? insurancePlan.id : '',
                                    planName: insurancePlan && insurancePlan.id === 'other' ? getValues('otherInsuranceplanName') : insurancePlan.name
                                },
                            },
                            xguarantor: {
                                fname: secondaryguarantor.fname,
                                lname: secondaryguarantor.lname,
                                email: secondaryguarantor.email,
                                phone: secondaryguarantor.phone,
                                dob: secondaryguarantor.dob,
                                gender: secondaryguarantor.gender,
                                registeras: "normal",
                                insurance: secondaryguarantor.insurance
                            }
                        });
                    } else if (!getValues('secondaryguarantor')) {
                        Object.assign(requestedData, {
                            isIndividual: true,
                            insurance: {
                                id: insurance.id,
                                name: insurance && insurance.id === 'other' ? getValues('otherInsurance') : insurance.name,
                                plan: insurancePlan && insurancePlan.id ? insurancePlan.id : '',
                                planName: insurancePlan && insurancePlan.id === 'other' ? getValues('otherInsuranceplanName') : insurancePlan.name
                            },
                            guarantor: {
                                fname: getValues('firstName'),
                                lname: getValues('lastName'),
                                email: getValues('email'),
                                phone: getValues('phone'),
                                dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                registeras: "normal",
                                password: getValues('password'),
                                gender: getValues('gender'),
                                insurance: {
                                    id: insurance.id,
                                    name: insurance && insurance.id === 'other' ? getValues('otherInsurance') : insurance.name,
                                    plan: insurancePlan && insurancePlan.id ? insurancePlan.id : '',
                                    planName: insurancePlan && insurancePlan.id === 'other' ? getValues('otherInsuranceplanName') : insurancePlan.name
                                },
                            }
                        });
                    }
                } else if (getValues('patienttype') === 'dependent') {
                    if (getValues('secondaryguarantor')[0] !== undefined) {
                        const primaryguarantor: any = guarantorList[0];
                        if (getValues('email') === primaryguarantor.email && getValues('phone') === primaryguarantor.phone) {
                            setError('globalError', { type: 'custom', message: 'Dependant email,mobile# and Primary guarantor email,mobile# can\'t be same.' });
                            return;
                        }
                        setError('globalError', {});
                        const secondaryguarantor: any = guarantorList[1];
                        Object.assign(requestedData, {
                            isIndividual: false,
                            insurance: {
                                id: insurance.id,
                                name: insurance && insurance.id === 'other' ? getValues('otherInsurance') : insurance.name,
                                plan: insurancePlan && insurancePlan.id ? insurancePlan.id : '',
                                planName: insurancePlan && insurancePlan.id === 'other' ? getValues('otherInsuranceplanName') : insurancePlan.name
                            },
                            guarantor: {
                                fname: primaryguarantor.fname,
                                lname: primaryguarantor.lname,
                                email: primaryguarantor.email,
                                phone: primaryguarantor.phone,
                                dob: primaryguarantor.dob,
                                registeras: "normal",
                                gender: primaryguarantor.gender,
                                insurance: primaryguarantor.insurance
                            },
                            xguarantor: {
                                fname: secondaryguarantor.fname,
                                lname: secondaryguarantor.lname,
                                email: secondaryguarantor.email,
                                phone: secondaryguarantor.phone,
                                dob: secondaryguarantor.dob,
                                gender: secondaryguarantor.gender,
                                registeras: "normal",
                                insurance: secondaryguarantor.insurance
                            }
                        });
                    } else if (getValues('primaryguarantor') !== undefined) {
                        const primaryguarantor: any = guarantorList[0];
                        if (getValues('email') === primaryguarantor.email && getValues('phone') === primaryguarantor.phone) {
                            setError('globalError', { type: 'custom', message: 'Dependant email,mobile# and Primary guarantor email,mobile# can\'t be same.' });
                            return;
                        }
                        setError('globalError', {});
                        Object.assign(requestedData, {
                            isIndividual: false,
                            insurance: {
                                id: insurance.id,
                                name: insurance && insurance.id === 'other' ? getValues('otherInsurance') : insurance.name,
                                plan: insurancePlan && insurancePlan.id ? insurancePlan.id : '',
                                planName: insurancePlan && insurancePlan.id === 'other' ? getValues('otherInsuranceplanName') : insurancePlan.name
                            },
                            guarantor: {
                                fname: primaryguarantor.fname,
                                lname: primaryguarantor.lname,
                                email: primaryguarantor.email,
                                phone: primaryguarantor.phone,
                                dob: primaryguarantor.dob,
                                registeras: "normal",
                                gender: primaryguarantor.gender,
                                insurance: primaryguarantor.insurance
                            },
                        });
                    }
                }
            }
            setIsLoading(true);
            registerUser(requestedData).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    setPhone(getValues('phone'));
                    setEmail(getValues('email'));
                    setPatientId(success.id);
                    setIsShowOTPModal(true);
                    setIsRegister(false);
                }
            }).catch((err) => {
                setIsLoading(false);
                setError('globalError', { type: 'custom', message: err.msg ? err.msg : 'Theres something wrong.' });
            });
        } catch (err) {
            setIsLoading(false);
            setError('globalError', { type: 'custom', message: err.msg ? err.msg : 'Theres something wrong.' });
        }
    }
    const postLoginAction = (data: any) => {
        setIsLoading(true);
        signInWithCustomToken(data.jwtToken).then((response) => {
            if (data.insurance && data.iPlan) {
                dispatch(addPrimaryInsurance({
                    insurancePlan: data.insurance.name,
                    insurencePlanName: data.iPlan.name
                }));
            }
            setIsLoading(false);
            if (response) {
                const key1 = 'xprUser.patient-' + data.id;
                const key2 = 'xpr_user_id';
                const key3 = 'xprAuthUser';
                const key4 = 'xprUserName';
                const key5 = 'jwtToken';
                const key6 = 'xprIsGuarantor';
                var json: any = {};
                json[key1] = true;
                json[key2] = data.uniqueId;
                json[key3] = data.authParam;
                json[key4] = data.firstName + " " + data.lastName;
                json[key5] = data.jwtToken;
                json[key6] = data.isGuarantor;
                Cookies.remove('patient_cookie');
                Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                Cookies.set('patient_cookie', json, { domain: configs.COOKIE_DOMAIN });
                Cookies.set('patient_cookie', json);
                // history.replace('/');
                setIsLoading(false);
                setIsShowOTPModal(false);
                props.onRegisterSuccess();
            } else {
                setIsLoading(false);
                setError('globalError', { type: 'custom', message: 'Theres something wrong.' });
            }
        }).catch((error) => {
            setIsLoading(false);
            setError('globalError', { type: 'custom', message: 'Theres something wrong.' });
        });
    }
    return (
        <>
            <Modal className="master_popup public_popup regPopup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={isRegister} onHide={props.hideModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Sign up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label className="label-form">First name</label>
                                <input type="text" id="firstName" name="firstName" className="form-control" placeholder="Enter first name" ref={register({ required: true })} />
                                {
                                    (errors && errors.firstName && errors.firstName.type === 'required') &&
                                    <div className="is-invalid">First name is required</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="label-form">Last name</label>
                                <input type="text" className="form-control" name="lastName" placeholder="Enter last name" ref={register({ required: true })} />
                                {
                                    (errors && errors.lastName && errors.lastName.type === 'required') &&
                                    <div className="is-invalid">Last name is required</div>
                                }
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label className="label-form">Email address</label>
                                <input type="email" className="form-control" name="email" placeholder="Enter email" ref={register({
                                    required: true,
                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                })} />
                                {
                                    (errors && errors.email && errors.email.type === 'required') &&
                                    <div className="is-invalid">Email is required</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="label-form">Mobile number</label>
                                <input type="text" className="form-control" name="phone" maxLength={10} placeholder="Enter mobile"
                                    ref={register({ required: true, minLength: 10, maxLength: 10 })} />
                                {
                                    (errors && errors.phone && errors.phone.type === 'required') &&
                                    <div className="is-invalid">Mobile number is required</div>
                                }
                                {
                                    (errors && errors.phone && errors.phone.type === 'minLength') &&
                                    <div className="is-invalid">Mobile number is invalid</div>
                                }
                                {
                                    (errors && errors.phone && errors.phone.type === 'maxLength') &&
                                    <div className="is-invalid">Mobile number is invalid</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="label-form">Gender</label>
                                <select className="form-control" name="gender" ref={register({ required: true })}>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label className="d-block form-label">Date of Birth</label>
                                <Controller
                                    name="dob"
                                    control={control}
                                    defaultValue={moment().subtract(1, 'day')}
                                    render={(props) => (
                                        <KeyboardDatePicker
                                            id="date-picker-dialog"
                                            format="YYYY/MM/DD"
                                            name="dob"
                                            value={props.value}
                                            maxDate={moment().subtract(1, 'day')}
                                            onChange={(date: any) => props.onChange(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    )}
                                ></Controller>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label className="label-form">Password</label>
                                <input type="password" className="form-control" name="password" placeholder="Enter password"
                                    ref={register({
                                        required: true,
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                                    })} />
                                {
                                    (errors && errors.password && errors.password.type === 'required') &&
                                    <div className="is-invalid">Password is required</div>
                                }
                                {
                                    (errors && errors.password && errors.password.type === 'pattern') &&
                                    <div className="is-invalid">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="label-form">Re-type Password</label>
                                <input type="password" className="form-control" name="cpassword" placeholder="Enter re-type password"
                                    ref={register({
                                        required: true,
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                                    })} />
                                {
                                    (errors && errors.cpassword && errors.cpassword.type === 'required') &&
                                    <div className="is-invalid">Confirm Password is required</div>
                                }
                                {
                                    (errors && errors.cpassword && errors.cpassword.type === 'pattern') &&
                                    <div className="is-invalid">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                                }
                            </div>
                            <div className="col-md-6 d-none">
                                <label className="label-form">Is patient Dependent or Individual ?</label>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="form-check mr-3">
                                        <input className="form-check-input" type="radio" id="individual" value="individual" name="patienttype" ref={register({ required: true })} />
                                        <label className="form-check-label" htmlFor="individual">Individual</label>
                                    </div>
                                    <div className="form-check mr-3">
                                        <input className="form-check-input" type="radio" id="dependent" value="dependent" name="patienttype" ref={register({ required: true })} />
                                        <label className="form-check-label" htmlFor="dependent">Dependent</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-none">
                                <label className="label-form">
                                    Payment through insurance ?
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="form-check mr-3">
                                        <input className="form-check-input" type="radio" name="paythroughinsurance" id="payinsurance-yes" value="yes" ref={register({ required: true })} />
                                        <label className="form-check-label" htmlFor="payinsurance-yes">Yes</label>
                                    </div>
                                    <div className="form-check mr-3">
                                        <input className="form-check-input" type="radio" name="paythroughinsurance" id="payinsurance-no" value="no" ref={register({ required: true })} />
                                        <label className="form-check-label" htmlFor="payinsurance-no">No</label>
                                    </div>
                                </div>
                                {
                                    (errors && errors.paythroughinsurance && errors.paythroughinsurance.type === 'required') &&
                                    <div className="is-invalid">Pay through insurance is required.</div>
                                }
                                {
                                    watch('paythroughinsurance') === 'no' &&
                                    <label className="text-muted fw-normal small">Payment through cash/credit/debit.</label>
                                }
                            </div>
                            {
                                (watch('paythroughinsurance') === 'yes') &&
                                <>
                                    <div className="col-md-6 mt-3 mt-md-0">
                                        <label className="label-form">Insurance name:</label>
                                        <select className="form-control" name="insurancename" ref={register({ required: true })}>
                                            {
                                                masterInsuranceList.map((item: any) => (
                                                    <option value={item.id} key={item.id + item.name}>{item.name}</option>
                                                ))
                                            }
                                        </select>
                                        {
                                            (errors && errors.insurancename && errors.insurancename.type === 'required') &&
                                            <div className="is-invalid">Insurance name is required.</div>
                                        }
                                        {
                                            (watch('insurancename') === 'other') &&
                                            <input type="text" className="form-control" placeholder="Enter insurance plan name" name="otherInsurance" ref={register({ required: true })} />
                                        }
                                        {
                                            (errors && errors.otherInsurance && errors.otherInsurance.type === 'required') &&
                                            <div className="is-invalid">Insurance plan name is required.</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <label className="label-form">Insurance plan name:</label>
                                        <select className="form-control" name="insuranceplanname" ref={register({ required: true })}>
                                            {
                                                insurancePlanList.map((item: any) => (
                                                    <option value={item.id} key={item.id + item.name}>{item.name}</option>
                                                ))
                                            }
                                        </select>
                                        {
                                            (errors && errors.insuranceplanname && errors.insuranceplanname.type === 'required') &&
                                            <div className="is-invalid">Insurance plan name is required.</div>
                                        }
                                        {
                                            (watch('insuranceplanname') === 'other') &&
                                            <input type="text" className="form-control" placeholder="Enter insurance plan name" name="otherInsuranceplanName" ref={register({ required: true })} />
                                        }
                                        {
                                            (errors && errors.otherInsuranceplanName && errors.otherInsuranceplanName.type === 'required') &&
                                            <div className="is-invalid">Insurance plan name is required.</div>
                                        }
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label className="label-form">Guarantor info</label>
                                        {
                                            watch('paythroughinsurance') === 'yes' && watch('patienttype') === 'individual' &&
                                            <div className="d-flex align-items-center flex-wrap">
                                                <div className="form-check my-2 mr-3">
                                                    <input checked={true} className="form-check-input" type="checkbox" name="selfguarantor" id="selfguarantor" value="self" disabled
                                                        ref={register({ required: true })} />
                                                    <label className="form-check-label" htmlFor="selfguarantor">Self</label>
                                                </div>
                                                <div className="form-check my-2 mr-3">
                                                    <input className="form-check-input" type="checkbox" name="secondaryguarantor" id="secondaryguarantor"
                                                        disabled={!watch('selfguarantor')}
                                                        value="secondary" ref={register} />
                                                    <label className="form-check-label" htmlFor="secondaryguarantor">Secondary</label>
                                                </div>
                                                <div className="formLinks text-primary">
                                                    {
                                                        ((watch('secondaryguarantor') === 'secondary' || watch('secondaryguarantor')[0] && watch('secondaryguarantor')[0] == "secondary") && watch('selfguarantor')) &&

                                                        <a href="javascript:void(0)" onClick={() => {
                                                            setShowGuarantorInfoModal(true);
                                                        }}>Click to add guarantor info</a>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            watch('paythroughinsurance') === 'yes' && watch('patienttype') === 'dependent' &&
                                            <>
                                                <div className="d-flex align-items-center flex-wrap">
                                                    <div className="form-check my-2 mr-3">
                                                        <input checked={true} className="form-check-input" type="checkbox" name="primaryguarantor" id="primaryguarantor" value="primary" disabled ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="primaryguarantor">Primary Guarantor</label>
                                                    </div>
                                                    <div className="form-check my-2 mr-3">
                                                        <input className="form-check-input" type="checkbox" name="secondaryguarantor" id="secondaryguarantor" value="secondary" ref={register} />
                                                        <label className="form-check-label" htmlFor="secondaryguarantor">Secondary Guarantor</label>
                                                    </div>
                                                    <div className="formLinks text-primary">
                                                        {
                                                            (watch('primaryguarantor') || watch('secondaryguarantor')) &&
                                                            <a href="javascript:void(0)" onClick={() => {
                                                                setShowGuarantorInfoModal(true);
                                                            }}>Click to add guarantor info</a>
                                                        }
                                                    </div>
                                                </div>

                                            </>
                                        }
                                    </div>
                                    {
                                        (watch('patienttype') === 'individual' && guarantorList.length === 1) &&
                                        <div className="alert alert-success alert-dismissible">Secondary Guarantor : {
                                            guarantorList[0].fname + " " + guarantorList[0].lname + " (" +
                                            guarantorList[0].email + ")"
                                        }</div>
                                    }

                                    {
                                        (watch('patienttype') === 'dependent' && guarantorList.length > 0) &&
                                        <>
                                            {
                                                (watch('primaryguarantor') && guarantorList[0]) &&
                                                <div className="alert alert-success alert-dismissible">Primary Guarantor : {
                                                    guarantorList[0].fname + " " + guarantorList[0].lname + " (" +
                                                    guarantorList[0].email + ")"
                                                }</div>
                                            }
                                            {
                                                (watch('secondaryguarantor') && guarantorList[1]) &&
                                                <div className="alert alert-success alert-dismissible">Secondary Guarantor : {
                                                    guarantorList[1].fname + " " + guarantorList[1].lname + " (" +
                                                    guarantorList[1].email + ")"
                                                }</div>
                                            }
                                        </>
                                    }
                                </>
                            }
                            <input type="hidden" name="globalError" ref={register} />
                            {
                                (errors && errors.globalError && errors.globalError.type === 'custom') &&
                                <div className="text-danger error">{errors.globalError.message}</div>
                            }
                        </div>
                        <div className="d-flex">
                            <label><b>Note:</b> Option to add minor/spouse or any dependent family member will be available after the sign up step.</label>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="formLinks">
                                {/* <a onClick={()=>{props.clickBackToSignIn()}}>Back to Sign in</a> */}
                            </div>
                            <button disabled={isLoading} onClick={handleSubmit(onRegisterClick)} type="submit" className="btn btn-dark">
                                <span className="aft_load right">
                                    <>
                                        {
                                            isLoading &&
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }
                                    </>
                                </span>SIGN UP</button>
                        </div>
                        {
                            showGurantorInfoModal &&
                            <GuarantorInfoModal hideModal={() => {
                                setShowGuarantorInfoModal(false);
                            }}
                                patientType={watch('patienttype')}
                                selfguarantor={watch('selfguarantor')}
                                secondaryguarantor={watch('secondaryguarantor')}
                                primaryguarantor={watch('primaryguarantor')}
                                submit={(data: any) => {
                                    if (getValues('patienttype') === 'dependent') {
                                        setGuarantorList(data);
                                    } else if (getValues('patienttype') === 'individual') {
                                        setGuarantorList(data);
                                    }
                                    setShowGuarantorInfoModal(false);
                                }}
                                masterInsuranceList={masterInsuranceList}
                                selectedGuarantorList={guarantorList}
                            ></GuarantorInfoModal>
                        }
                    </>

                </Modal.Body>
            </Modal>
            {
                isShowOTPModal &&
                <OTPModal hideModal={() => {
                    setIsShowOTPModal(false);
                }}
                    sentEmail={email}
                    sentPhone={phone}
                    patientId={patientId}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    codeVerifySuccessLogin={postLoginAction}
                ></OTPModal>
            }
        </>
    )
}